<template>
    <v-container class="pt-2">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getAdminSetupModal.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getAdminSetupErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('notify-users:basicInfo')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-5" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-2">
                                        <Separator
                                            :text="$t('notify-users:basicInfo')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row><v-col cols="6"> </v-col></v-row>
                                <v-row>
                                    <v-col cols="6" md="6">
                                        <v-text-field
                                            v-model="getAdminSetupModal.name"
                                            :label="`${$t(
                                                'notify-users:name'
                                            )}*`"
                                            :error-messages="
                                                getAdminSetupErrors.stepOne
                                                    .fields.name
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="6">
                                        <v-text-field
                                            v-model="
                                                getAdminSetupModal.lastName
                                            "
                                            :label="`${$t(
                                                'notify-users:lastName'
                                            )}*`"
                                            :error-messages="
                                                getAdminSetupErrors.stepOne
                                                    .fields.lastName
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row
                                    ><v-col cols="6" md="6">
                                        <v-text-field
                                            v-model="getAdminSetupModal.login"
                                            :label="`${$t(
                                                'notify-users:login'
                                            )}*`"
                                            :error-messages="
                                                getAdminSetupErrors.stepOne
                                                    .fields.login
                                            "
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="6">
                                        <v-text-field
                                            v-model="getAdminSetupModal.phoneNumber"
                                            :label="`${$t(
                                                'notify-users:phone'
                                            )}*`"
                                            :error-messages="
                                                getAdminSetupErrors.stepOne
                                                    .fields.phoneNumber
                                            "
                                        ></v-text-field> </v-col
                                ></v-row>

                                <v-row
                                    ><v-col cols="12" md="12" class="pt-0">
                                        <v-text-field
                                            v-model="
                                                getAdminSetupModal.comments
                                            "
                                            :label="`${$t(
                                                'notify-users:comments'
                                            )}`"
                                        ></v-text-field> </v-col
                                ></v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users', 'global'] },
    computed: {
        ...mapGetters([
            'getAdminSetupDetails',
            'getAdminSetupModal',
            'getAdminSetupErrors',
        ]),
    },
    methods: {
        checkLength(v) {
            if (v && v.length >= 8) return 'success--text'
        },
    },
}
</script>
<style lang="sass"></style>
