<template>
    <v-container>
        <DetailsTabsHeader
            :registryStatus="getAdminSetupStatuses"
            :recordIndex="`${getAdminSetupDetails.name} ${getAdminSetupDetails.lastName}`"
            :registryTitle="'Narzędzia administratora'"
            :registryUrl="'admin-setup'"
            :actualStatus="getAdminSetupDetails.status"
            @status-changed="changeStatus"
        />
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('global:edit')"
                    :open="open"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        slot="buttons"
                        :action="'edit'"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details--innerpage">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                small
                                class="buttons--add margin--1"
                                @click="editUser()"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('parts:editUser') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>
<script>
import Information from '../../../components/Registries/AdminSetup/Details/Information.vue'
import Buttons from './../../../components/Registries/AdminSetup/Modal/Buttons.vue'
import AEContent from './../../../components/Registries/AdminSetup/Modal/Content.vue'
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Buttons,
    },
    data() {
        return {
            items: [],
            open: false,
        }
    },

    computed: {
        ...mapGetters([
            'getAdminSetupStatuses',
            'getAdminSetupDetails',
            'getAdminSetupDetails',
        ]),
    },
    methods: {
        ...mapActions(['fetchAdminSetupUser', 'updateAdminSetupStatus']),
        ...mapMutations(['setAdminSetupModal']),
        closeModal() {
            // this.clearUsersErrors()
            this.open = false
        },
        editUser() {
            this.setAdminSetupModal()
            this.open = true
        },
         changeStatus(status) {
            this.updateAdminSetupStatus({ status, id: this.getAdminSetupDetails._id })
        },
    },
    components: {
        Information,
        Buttons,
        AEContent,
    },
    beforeRouteEnter(to, from, next) {
        // store.dispatch('fetchNotifyUsersRegistry')
        store.dispatch('fetchAdminSetupUser', { id: to.params.id, next })
    },
}
</script>

<style></style>
