<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            class="buttons buttons--add"
            :disabled="disabled"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['updateAdminSetupUser']),
        async save() {
            // this.disabled = true
            // console.log('SAVE')
            // // let success = await this.createUser()
            // if (success) this.$emit('closeModal')
            // this.disabled = false
        },
        async edit() {
            console.log('EDIT')
            this.disabled = true
            let success = await this.updateAdminSetupUser({ id: this.$route.params.id })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
    },
}
</script>
