<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col class="py-5 d-flex flex-column" cols="12" md="12">
                        <div class="d-flex flex-column px-5 mt-4">
                            <span class="title" data-cy-users="fnln"
                                >{{ getAdminSetupDetails.name }}
                                {{ getAdminSetupDetails.lastName }}</span
                            >
                        </div>
                        <div class="d-flex flex-column px-5 mt-4">
                            <span class="title" data-cy-users="fnln"
                                >Data dodania:
                                {{
                                    $moment(
                                        getAdminSetupDetails.createdAt
                                    ).format('DD/MM/YYYY')
                                }}</span
                            >
                        </div>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12">
                        <Separator
                            :text="$t('NotifyAdminSetup:personInformation')"
                        />
                    </v-col>
                </v-row>
                <!-- <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('users:userData')" />
                    </v-col>
                </v-row> -->
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            readonly
                            data-cy-users="isAdmin"
                            :value="
                                getAdminSetupDetails.isAdmin === true
                                    ? $t('global:admin')
                                    : $t('global:user')
                            "
                            :label="`${$t('NotifyAdminSetup:typeOfUser')}`"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-text-field
                            readonly
                            data-cy-users="login"
                            v-model="getAdminSetupDetails.login"
                            :label="`${$t('users:login')}`"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            readonly
                            v-model="getAdminSetupDetails.phoneNumber"
                            :label="$t('notify-users:phone')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            readonly
                            data-cy-users="comments"
                            v-model="getAdminSetupDetails.comments"
                            :label="$t('NotifyAdminSetup:comments')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users'] },
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['getAdminSetupDetails']),
    },
}
</script>
<style lang="scss" scoped>
.details__card {
    .title {
        font-size: clamp(0.75rem, 1.95rem, 1.15rem) !important;
    }
}
</style>
